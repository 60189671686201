<template>
  <section>
  <header :class="data.type">
    <h1><button @click="$router.back()"><i class="fa fa-arrow-left"></i></button> {{ data.name }}</h1>
  </header>
  <div :data-date="date" :class="[weekDay, { today: isToday }, { firstday: isFirstday }]">
    
    <p><i class="fa fa-calendar fa-fw"></i> {{ data.date }}</p>
    <p><i class="fa fa-clock fa-fw"></i> {{ data.fromTime }} - {{ data.toTime }}</p>
    <p><i class="fa fa-location fa-fw"></i> {{ data.location }}</p>
    <a v-if="data.datumprikker" type="button" :href="data.datumprikker">datumprikker</a>
    <p v-html="data.details"></p>
  </div>
  <div>
    <p v-for="person in data.absence" :key="person.id" class="member"><i :class="['fa','fa-fw',{'fa-user': !person.maybe},{'fa-user-times': person.maybe == 0},{'fa-question': person.maybe == 1},{'fa-hourglass-half': person.maybe == 2}]"></i> {{ person.name }}</p>
  </div>
  </section>
</template>
  
<script>
import Localbase from 'localbase'

const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
let db = new Localbase('db')
db.config.debug = false
  
export default {
  name: "EventComponent",
  props: {
    day: {
      type: String,
      default: "1",
    },
    month: {
      type: String,
      default: "1",
    },
    year: {
      type: String,
      default: "2018",
    },
    id: {
      type: String,
      default: "623",
    },
  },
  data() {
    return {
      date: new Date(this.year, this.month, this.day).toLocaleDateString("nl-NL"),
      weekDay: weekDays[new Date(this.year, this.month, this.day).getDay()],
      isToday:
        new Date(this.year, this.month, this.day).toLocaleDateString("nl-NL") == new Date().toLocaleDateString("nl-NL"),
      isFirstday: this.day === 1,
      data: this.getEventData(),
    };
  },
  methods: {
    async getEventData() {
      db.collection('events').doc(this.id).get()
        .then(response => {
          response.type = this.renameTypes(response.type);
          return this.data = response
        })
    },
    renameTypes(type) {
      switch (type) { 
        case "1": return "repetition"
        case "2": return "activity"
        case "3": return "holiday"
        case "4": return "performance pending"
        case "5": return "performance"
        case "6": return "exercition"
        case "7": return "black-face"
        case "8": return "canceled"
        case "9": return "canceled corona"
      }
    }
  }
}
</script>
  
<style scoped>
section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

header h1 {
  font-family: monospace;
  font-weight: bold;
  margin: 0;
  padding: 16px 8px
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

div{
  display: block;
  font-family: monospace;
  background: #eee;
  margin: 0;
  border-collapse: collapse;
  min-height: 100px;
  padding: 8px;
}
  
p {
  margin: 0;
  padding: 0;
}
  
.saturday,
.sunday {
  background: #ddd;
}
.today {
  border: 2px solid red;
}

@media screen and (max-width: 600px) {
  div{
    display: inline-block;
    font-family: monospace;
    background: #eee;
    margin: 0;
    border-collapse: collapse;
    min-height: 10px;
    padding: 8px;
  }
}
/* repetition */
.repetition {
  background: lightsalmon;
}

/* activity */
.activity {
  background: red;
}

/* holiday */
.holiday {
  background: blue;
}

/* performance pending*/
.performance.pending {
  background: green;
}

/* perfomance */
h1.performance {
  color: #fff;
}
.performance {
  background: darkblue;
}

/* exercition */
.exercition {
  background: lightblue;
}

/* black-face */
.black-face {
  background: lightblue;
}

.canceled {
  text-decoration: line-through;
}
/* canceled */
.canceled {
  background: black;
  color: #fff;
}

/* canceled-corona */
.canceled.corona {
  background: lightblue;
}

.member {
  padding: 4px;
}

.member:has(.fa-user-times) {
  background: red;
  color: #fff;
}
.member:has(.fa-question) {
  background: orange;
  color: #fff;
}
.member:has(.fa-hourglass-half) {
  background: blue;
  color: #fff;
}
</style>
