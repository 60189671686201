<template>
  <h2>{{ heading }}</h2>
  <span>
    <Day v-for="n in days" :key="n" :day="n" :month="month" :year="year" />
  </span>
</template>

<script>
import Day from './Day.vue'
export default {
  name: 'MonthComponent',
  components: {
    Day,
  },
  props: {
    heading: {
      type: String,
      default: 'Januari 2018',
    },
    days: {
      type: Number,
      default: 28,
    },
    month: {
      type: Number,
      default: 1,
    },
    year: {
      type: Number,
      default: 2018,
    },
  },
  methods: {
    date() {},
  },
}
</script>

<style scoped>
header {
  background: var(--laurentius);
  color: white;
}

h2 {
  line-height: 20px;
  padding: 20px;
  margin: 0;
  font-family: monospace;
}

span {
  display: grid;
  grid-template-columns: repeat(7, minmax(0,1fr));
  grid-gap: 8px;
}

@media screen and (max-width: 600px) {
  span {
    grid-gap: 2px;
  }
}
</style>

<style>
.monday.firstday {
  grid-column: 1/2;
}
.tuesday.firstday {
  grid-column: 2/3;
}
.wednesday.firstday {
  grid-column: 3/4;
}
.thursday.firstday {
  grid-column: 4/5;
}
.friday.firstday {
  grid-column: 5/6;
}
.saturday.firstday {
  grid-column: 6/7;
}
.sunday.firstday {
  grid-column: 7/-1;
}
</style>
