<template>
  <div :data-date="date" :class="[weekDay, { today: isToday }, { firstday: isFirstday }]">
    <p>{{ day }}</p>
  </div>
</template>

<script>
const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export default {
  name: "DayComponent",
  props: {
    day: {
      type: Number,
      default: 1,
    },
    month: {
      type: Number,
      default: 1,
    },
    year: {
      type: Number,
      default: 2018,
    },
  },
  data() {
    return {
      date: new Date(this.year, this.month, this.day).toLocaleDateString("nl-NL"),
      weekDay: weekDays[new Date(this.year, this.month, this.day).getDay()],
      isToday:
        new Date(this.year, this.month, this.day).toLocaleDateString("nl-NL") == new Date().toLocaleDateString("nl-NL"),
      isFirstday: this.day === 1,
    };
  }
};
</script>

<style scoped>

div {
  display: inline-block;
  font-family: monospace;
  background: #eee;
  margin: 0;
  border-collapse: collapse;
  min-height: 100px;
  padding: 8px;
}

p {
  margin: 0;
  padding: 0;
}

.saturday,
.sunday {
  background: #ddd;
}
.today {
  border: 2px solid red;
}

@media screen and (max-width: 600px) {
  div {
    display: inline-block;
    font-family: monospace;
    background: #eee;
    border: 0;
    margin: 0;
    border-collapse: collapse;
    min-height: 10px;
    padding: 4px;
  }
}
</style>

<style>
.event {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.event:before {
  content: "";
  border-radius: 4px;
  aspect-ratio: 1/1;
  height: 8px;
  margin-right: 8px;
  display: inline-block;
}

/* repetition */
.repetition:before {
  background: lightsalmon;
}

/* activity */
.activity:before {
  background: red;
}

/* holiday */
.holiday:before {
  background: blue;
}

/* performance pending*/
.performance.pending:before {
  background: green;
}

/* perfomance */
.performance:before {
  background: darkblue;
}

/* exercition */
.exercition:before {
  background: lightblue;
}

/* black-face */
.black-face:before {
  background: lightblue;
}

.canceled {
  text-decoration: line-through;
}
/* canceled */
.canceled:before {
  background: black;
}

/* canceled-corona */
.canceled.corona:before {
  background: lightblue;
}
</style>
