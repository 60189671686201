<template>
  <Header heading="Laurentius"/>
  <router-view/>
</template>

<script>
import Header from './components/Header.vue'
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  name: 'App',
  components: {
    Header,
  },
}
</script>

<style>
:root {
  --laurentius: #2c2148;
}

body {
  margin: 0;
}
</style>
