<template>
  <section>
    <div v-for="month in months" :key="month.index">
      <Month :heading="month.heading" :days="month.days" :month="month.month" :year="month.year" />
    </div>
  </section>
</template>

<script>
import Month from './Month.vue'
import Localbase from 'localbase'
import router from '../router'

let db = new Localbase('db');
db.config.debug = false

export default {
  name: 'CalendarComponent',
  components: {
    Month,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    monthAmount: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      months: this.generateMonths(),
    }
  },
  methods: {
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
    },
    generateMonths() {
      const monthNames = [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
      ]
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()
      const months = []

      for (let i = 0; i < this.monthAmount; i++) {
        const date = new Date(currentYear, currentMonth + i, 1)
        const year = date.getFullYear()
        const month = date.getMonth()
        const monthName = monthNames[month]
        const days = this.daysInMonth(month, year)

        months.push({
          heading: `${monthName} ${year}`,
          index: i,
          days,
          month,
          year,
        })
      }

      this.fetchData();
      return months
    },
    daysInMonth(month, year) {
      return [31, this.isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
    },
    fetchData() {
      const url = `https://planning.laurentiusvoorschoten.nl/api/planning.php`
      fetch(url,{mode:'cors'}).then((result) => {
        return result.json()
      }).then((data) => {
        for (let item of data) {
          if (item.id === '') continue
          let dateString = item.id.match(/d(\d*)m(\d*)j(\d*)/)
          let date = `${dateString[1]}-${dateString[2]}-${dateString[3]}`
          if (document.querySelector(`[data-date="${date}"]`)) {
            document.querySelector(`[data-date="${date}"]`).innerHTML += `<p data-id="${item.rowid}" class="event ${this.renameTypes(item.type)}">${item.description}</p>`
          }
        }
        this.$el.querySelectorAll('.event').forEach((button) => {
          button.addEventListener('click', (e) => {
            this.fetchEvent(e.target.dataset.id)
          })
        });
      });
    },
    fetchEvent(id) {
      const url = `https://planning.laurentiusvoorschoten.nl/api/event-details.php?q=${id}`
      fetch(url,{mode:'cors'}).then((response) => {
        if (response.status !== 200) {
          console.log(
            `Looks like there was a problem. Status Code: ${response.status}`
          );
          return;
        }
        response.json().then((data) => {
          let parts = data[id].datetime.split("-");
          db.collection('events').add(data[id], id).then(
            router.push(`event/${parts[2]}/${parts[1]}/${parts[0]}/${id}`)
          )
          
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    renameTypes(type) {
      switch (type) { 
        case "type1": return "repetition"
        case "type2": return "activity"
        case "type3": return "holiday"
        case "type4": return "performance pending"
        case "type5": return "performance"
        case "type6": return "exercition"
        case "type7": return "black-face"
        case "type8": return "canceled"
        case "type9": return "canceled corona"
      }
    }
  },
}
</script>

<style scoped>
header {
  background: var(--laurentius);
  color: white;
}

h1 {
  line-height: 20px;
  padding: 20px;
  margin: 0;
  font-family: monospace;
}
</style>
