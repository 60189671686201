<template>
  <header>
    <h1>{{ heading }}</h1>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    heading: {
      type: String,
      default: 'Januari 2018',
    },
  },
}
</script>

<style scoped>
header {
  background: var(--laurentius);
  color: white;
  position: sticky;
  top: 0;
}

h1 {
  line-height: 20px;
  padding: 20px;
  margin: 0;
  font-family: monospace;
}
</style>
