import { createRouter, createWebHistory } from 'vue-router'
import CalendarComponent from '../components/Calendar.vue'
import EventComponent from '../components/Event.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CalendarComponent
},
{
    path: '/event/:day/:month/:year/:id',
    name: 'Event',
    component: EventComponent,
    props: true,
    }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router